import './clients.scss'

import React, { Component } from "react"

import Image from 'gatsby-image'
import Layout from "../components/layout"
import SEO from '../components/seo'
import SimpleBar from 'simplebar-react'
import { graphql } from "gatsby"

class ClientsTemplate extends Component {
  render() {
    const page = this.props.data.wordpressPage

    return (
      <Layout>
        <SEO title={page.yoast_meta.title} description={page.yoast_meta.desc} />
        <section className="page-left">
          <div className="page-left--wrapper">
            <SimpleBar style={{ maxHeight: '100%' }}>
              <div className="page-left--header page-clients--header">
                <h1>Selected <strong>Clients</strong></h1>
              </div>
              <div className="page-left--content page-clients--content">
                <ul className="page-clients--list">
                  {page.acf.clients_list && page.acf.clients_list.map(item => {
                    return (
                      <li>
                        <Image fluid={item.logo.localFile.childImageSharp.fluid}></Image>
                      </li>
                    )
                  })}
                </ul>
                <h3>AGENCIES WE WORK WITH:</h3>
                <ul className="page-clients--agencies">
                  {page.acf.agencies_list && page.acf.agencies_list.map((item, i) => {
                    return (
                      <li>
                        <span>{`${i + 1}`.padStart(2, '0')}</span>
                        <span>{item.name}</span>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </SimpleBar>
          </div>
        </section>
        <section className="page-right page-clients-right">
        </section>
      </Layout>
    )
  }
}

export default ClientsTemplate

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      slug
      yoast_meta {
        title
        desc
      }
      acf {
        clients_list {
          client_name
          logo {
            localFile {
              childImageSharp {
                fluid(maxHeight: 200, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
        agencies_list {
          name
        }
      }
    }
    site {
      id
      siteMetadata {
        title
      }
    }
  }
`